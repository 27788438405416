<template>
  <div class="awards">
    <div class="awards-container" @scroll="scrollSlider">
      <div class="award-item" v-for="(item, idx) in sliders" :key="idx">
        <div class="i">
          <img :src="require('@/assets' + item.src)" alt="" />
        </div>
        <div class="t" v-html="item.text"></div>
      </div>
      <div class="award-offset"><div></div></div>
    </div>
    <ul class="awards-nav">
      <li v-for="(item, idx) in sliders.length - getCountImage" :key="idx">
        <a
          href="#"
          :class="{ active: step === idx }"
          @click.prevent="setStep(idx)"
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ProfileSliderOne",
  data: () => {
    return {
      step: 0,
      carouselPositions: [],
      halfContainer: 0,
      interval: false,
      timeout: false,
      sliders: [
        {
          src: "/i/icon-hands.svg",
          text:
            '<p>Максимально оценка по безопасности среди авиакомпаний мира</p> <p><span class="stars">★★★★★★★</span>Airline Ratings</p>'
        },
        {
          src: "/i/icon-r-ok.svg",
          text:
            "<p>В числе самых безопасных авиакомпаний мира</p><p>JACDEC 2020</p>"
        },
        {
          src: "/i/icon-cup.svg",
          text:
            "<p>Лидер по безопасности полетов среди отечественных перевозчиков</p><p>Safe Travel Score 2020</p>"
        },
        {
          src: "/i/icon-r-hand.svg",
          text:
            "<p>Лучшая авиакомпания на туристических направлениях</p><p>Skyway Service Award 2019</p>"
        },
        {
          src: "/i/icon-award.svg",
          text:
            "<p>Крупнейший в России эксплуатант</p><p>Superjet 100 и Boeing 747-400</p>"
        },
        {
          src: "/i/icon-r-hand.svg",
          text:
            "<p>Лучший эконом класс, внутренние регулярные перевозки</p><p>Skyway Service Award 2019</p>"
        },
        {
          src: "/i/icon-r-world.svg",
          text:
            "<p>Выполняем рейсы на Дальний Восток</p><p>программа плоских тарифов</p>"
        },
        {
          src: "/i/r-tech.svg",
          text:
            "<p>Победитель в номинации «За лучшую техническую службу»</p><p>MRO Russia & CIS 2020</p>"
        },
        {
          src: "/i/r-media.svg",
          text:
            "<p>Первая в РФ, запустившая детские наборы с дополненной реальностью</p><p>на рейсах свыше 6 часов</p>"
        },
        {
          src: "/i/r-around.svg",
          text:
            "<p>«Лучшая авиакомпания – противостояние вызовам 2020»</p><p>Skyway Service Award 2019</p>"
        },
        {
          src: "/i/r-team.svg",
          text:
            "<p>Единственная в РФ, открывшая авиационные Игротеки для детей</p><p>2 города профессий в Санкт-Петербурге</p>"
        }
      ]
    };
  },
  computed: {
    ...mapState("slider", {
      indexBanner: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    slidersComp() {
      return this.sliders.filter((item, key) => key <= 7);
    },
    ...mapState({
      width: state => state.width,
      height: state => state.height
    }),
    getCountImage() {
      if (this.width > 1439) {
        this.init();
        return 6;
      } else if (this.width > 1279) {
        this.init();
        return 5;
      } else if (this.width > 1023) {
        this.init();
        return 4;
      } else if (this.width > 767) {
        this.init();
        return 2;
      } else if (this.width > 413) {
        this.init();
        return 1;
      } else if (this.width > 374) {
        this.init();
        return 1;
      } else {
        this.init();
        return 1;
      }
    }
  },
  created() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.nextStep();
    }, 5000);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions("slider", {
      actionIndex: "index"
    }),
    init() {
      this.$nextTick(() => {
        this.carouselPositions = [];
        document.querySelectorAll(".awards-container img").forEach(div => {
          this.carouselPositions.push([div.offsetLeft - 112]);
        });
        this.halfContainer =
          document.querySelector(".awards-container").offsetWidth / 2;
      });
    },
    setStep(step) {
      try {
        this.step = step;
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.nextStep();
        }, 10000);
        let slider = document.querySelector(".awards-container");
        if (slider) {
          slider.scrollTo({
            left: this.carouselPositions[this.step][0],
            behavior: "smooth"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    prevStep() {
      if (this.step === 0) {
        this.step = this.carouselPositions.length - this.getCountImage;
      } else {
        this.step--;
      }
      let slider = document.querySelector(".awards-container");
      if (slider) {
        slider.scrollTo({
          left: this.carouselPositions[this.step][0],
          behavior: "smooth"
        });
      }
    },
    nextStep() {
      try {
        if (
          this.carouselPositions.length - this.getCountImage - 1 ===
          this.step
        ) {
          this.step = 0;
        } else {
          this.step++;
        }
        let slider = document.querySelector(".awards-container");
        if (slider) {
          slider.scrollTo({
            left: this.carouselPositions[this.step][0],
            behavior: "smooth"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    scrollSlider() {
      clearInterval(this.interval);
      document
        .querySelectorAll(".awards-container img")
        .forEach((div, index) => {
          let rect = div.getBoundingClientRect();
          if (rect.left >= 0 && rect.left <= 160) {
            if (this.timeout) {
              clearTimeout(this.timeout);
              this.timeout = false;
            }
            this.timeout = setTimeout(() => {
              this.step = index;
            }, 100);
          }
        });

      this.interval = setInterval(() => {
        this.nextStep();
      }, 5000);
    }
  }
};
</script>

<style scoped></style>
