import { render, staticRenderFns } from "./ProfileSliderThree.vue?vue&type=template&id=38fc8f74&scoped=true&"
import script from "./ProfileSliderThree.vue?vue&type=script&lang=js&"
export * from "./ProfileSliderThree.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38fc8f74",
  null
  
)

export default component.exports