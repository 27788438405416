import { render, staticRenderFns } from "./ProfileSliderOne.vue?vue&type=template&id=4915b300&scoped=true&"
import script from "./ProfileSliderOne.vue?vue&type=script&lang=js&"
export * from "./ProfileSliderOne.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4915b300",
  null
  
)

export default component.exports