<template>
  <div class="news">
    <div class="logos">
      <img src="@/assets/i/rossiya_logo.svg" alt="" class="r_l" />
      <img src="@/assets/i/irkut_logo.svg" alt="" class="i_l" />
    </div>
    <img src="@/assets/i/mc-logo.svg" alt="" class="mc_l" />
    <img src="@/assets/i/plane.png" alt="" class="r_p" />

    <div class="news-slider">
      <div class="news-slider-container" @scroll="scrollSlider">
        <div class="slider-item" v-for="(item, idx) in sliders" :key="idx">
          <div class="i">
            <img :src="require('@/assets' + item.src)" alt="" />
          </div>
          <div class="t">
            <p v-html="item.text"></p>
          </div>
        </div>
      </div>
    </div>

    <ul class="news-nav">
      <li v-for="(item, idx) in sliders" :key="idx">
        <a
          href="#"
          :class="{ active: step === idx }"
          @click.prevent="setStep(idx)"
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ProfileSliderThree",
  data: () => {
    return {
      step: 0,
      carouselPositions: [],
      halfContainer: 0,
      interval: false,
      timeout: false,
      sliders: [
        {
          src: "/img/img-9.jpg",
          text: "В 2022 году в нашем парке самолетов появится новый MC-21."
        },
        {
          src: "/img/img-10.jpg",
          text: "В 2022 году в нашем парке самолетов появится новый MC-21."
        },
        {
          src: "/img/img-11.jpg",
          text: "В 2022 году в нашем парке самолетов появится новый MC-21."
        },
        {
          src: "/img/img-12.jpg",
          text: "В 2022 году в нашем парке самолетов появится новый MC-21."
        },
        {
          src: "/img/img-13.jpg",
          text: "В 2022 году в нашем парке самолетов появится новый MC-21."
        }
      ]
    };
  },
  computed: {
    ...mapState("slider", {
      indexBanner: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    ...mapState({
      width: state => state.width,
      height: state => state.height
    }),
    getSizeImage() {
      if (this.width > 1439) {
        this.init();
        return 7;
      } else if (this.width > 1279) {
        this.init();
        return 6;
      } else if (this.width > 1023) {
        this.init();
        return 5;
      } else if (this.width > 767) {
        this.init();
        return 4;
      } else if (this.width > 413) {
        this.init();
        return 3;
      } else if (this.width > 374) {
        this.init();
        return 2;
      } else {
        this.init();
        return 1;
      }
    }
  },
  created() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.nextStep();
    }, 10000);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions("slider", {
      actionIndex: "index"
    }),
    init() {
      this.$nextTick(() => {
        this.carouselPositions = [];
        document.querySelectorAll(".news-slider-container img").forEach(div => {
          this.carouselPositions.push([div.offsetLeft - 150]); // add to array the positions information
        });

        this.halfContainer =
          document.querySelector(".news-slider-container").offsetWidth / 2;
      });
    },
    setStep(step) {
      try {
        this.step = step;
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.nextStep();
        }, 10000);
        let slider = document.querySelector(".news-slider-container");
        if (slider) {
          slider.scrollTo({
            left: this.carouselPositions[this.step][0],
            behavior: "smooth"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    prevStep() {
      if (this.step === 0) {
        this.step = this.carouselPositions.length - 1;
      } else {
        this.step--;
      }
      let slider = document.querySelector(".news-slider-container");
      if (slider) {
        slider.scrollTo({
          left: this.carouselPositions[this.step][0],
          behavior: "smooth"
        });
      }
    },
    nextStep() {
      try {
        if (this.carouselPositions.length - 1 === this.step) {
          this.step = 0;
        } else {
          this.step++;
        }
        let slider = document.querySelector(".news-slider-container");
        if (slider) {
          slider.scrollTo({
            left: this.carouselPositions[this.step][0],
            behavior: "smooth"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    scrollSlider() {
      clearInterval(this.interval);
      document
        .querySelectorAll(".news-slider-container  img")
        .forEach((div, index) => {
          let rect = div.getBoundingClientRect();
          if (rect.left >= 0 && rect.left <= 150) {
            this.step = index;
          }
        });
      this.interval = setInterval(() => {
        this.nextStep();
      }, 10000);
    }
  }
};
</script>

<style scoped></style>
