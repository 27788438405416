<template>
  <div class="full-width-slider">
    <div class="slider-container" @scroll="scrollSlider">
      <div
        class="slider-item"
        :class="{ 'last-item': sliders.length - 1 === idx }"
        v-for="(item, idx) in sliders"
        :key="idx"
      >
        <div class="i">
          <picture>
            <source
              :srcset="require('@/assets' + item.srcset)"
              media="(max-width: 767px)"
            />
            <img :src="require('@/assets' + item.src)" alt="" />
          </picture>
        </div>
        <div class="t">
          <p v-html="item.text"></p>
        </div>
      </div>
      <div class="slider-offset"><div></div></div>
    </div>
    <div class="dots"></div>
    <ul class="slider-nav">
      <li class="prev"><a href="#" @click.prevent="prevStep"></a></li>
      <li v-for="(item, idx) in sliders" :key="item.id">
        <a
          href="#"
          :class="{ active: step === idx }"
          @click.prevent="setStep(idx)"
        ></a>
      </li>
      <li class="next"><a href="#" @click.prevent="nextStep"></a></li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ProfileSliderTwo",
  data: () => {
    return {
      step: 0,
      carouselPositions: [],
      halfContainer: 0,
      interval: false,
      sliders: [
        {
          srcset: "/img/img-14-s.jpg",
          src: "/img/img-14.jpg",
          text:
            "Boeing 777-300 с изображением самого редкого дальневосточного леопарда"
        },
        {
          srcset: "/img/img-15-s.jpg",
          src: "/img/img-15.jpg",
          text: "Airbus A319 в ливрее, посвященной российскому спорту"
        },
        {
          srcset: "/img/img-16-s.jpg",
          src: "/img/img-16.jpg",
          text: " С 2014 года являемся официальным перевозчиком ФК «Зенит»"
        },
        {
          srcset: "/img/img-17-s.jpg",
          src: "/img/img-17.jpg",
          text: "Boeing 747 (EI-XLD) в специальной тигриной ливрее"
        },
        {
          srcset: "/img/img-18-s.jpg",
          src: "/img/img-18.jpg",
          text:
            "Перевозим лекарственные препараты по стране для подопечных благотворительного фонда Константина Хабенского"
        }
      ]
    };
  },
  computed: {
    ...mapState("slider", {
      indexBanner: state => state.index,
      indexLoading: state => state.indexLoading
    }),
    ...mapState({
      width: state => state.width,
      height: state => state.height
    }),
    getSizeImage() {
      if (this.width > 1439) {
        this.init();
        return 7;
      } else if (this.width > 1279) {
        this.init();
        return 6;
      } else if (this.width > 1023) {
        this.init();
        return 5;
      } else if (this.width > 767) {
        this.init();
        return 4;
      } else if (this.width > 413) {
        this.init();
        return 3;
      } else if (this.width > 374) {
        this.init();
        return 2;
      } else {
        this.init();
        return 1;
      }
    }
  },
  created() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.nextStep();
    }, 10000);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions("slider", {
      actionIndex: "index"
    }),
    init() {
      this.$nextTick(() => {
        this.carouselPositions = [];
        document.querySelectorAll(".slider-container picture").forEach(div => {
          this.carouselPositions.push([
            div.offsetLeft,
            div.offsetLeft + div.offsetWidth
          ]); // add to array the positions information
        });
        this.halfContainer =
          document.querySelector(".slider-container").offsetWidth / 2;
      });
    },
    setStep(step) {
      try {
        this.step = step;
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.nextStep();
        }, 10000);
        let slider = document.querySelector(".slider-container");
        if (slider) {
          slider.scrollTo({
            left: this.carouselPositions[this.step][0],
            behavior: "smooth"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    prevStep() {
      if (this.step === 0) {
        this.step = this.carouselPositions.length - 1;
      } else {
        this.step--;
      }
      let slider = document.querySelector(".slider-container");
      if (slider) {
        slider.scrollTo({
          left: this.carouselPositions[this.step][0],
          behavior: "smooth"
        });
      }
    },
    nextStep() {
      try {
        if (this.carouselPositions.length - 1 === this.step) {
          this.step = 0;
        } else {
          this.step++;
        }
        let slider = document.querySelector(".slider-container");
        if (slider) {
          slider.scrollTo({
            left: this.carouselPositions[this.step][0],
            behavior: "smooth"
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    scrollSlider() {
      clearInterval(this.interval);
      document
        .querySelectorAll(".slider-container  picture")
        .forEach((div, index) => {
          let rect = div.getBoundingClientRect();
          if (rect.left >= 0 && rect.left <= 180) {
            this.step = index;
          }
        });
      this.interval = setInterval(() => {
        this.nextStep();
      }, 10000);
    }
  }
};
</script>

<style scoped></style>
